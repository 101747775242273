import './SkillsList.css';
import './../../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import Tilt from 'react-tilt'
import Fade from 'react-reveal/Fade';

// PhotoSwipe imports
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import skillsData from './../../assets/content/skills.json'
import Header from './../../Header/Header';

class SkillsList extends Component {
  componentDidMount() {
  }

  render() {
    return <div className="page-padding padding-40-top">
        <Header blackLink={true}></Header>
        <Table>
            <Row>
            <Col>
                <Fade bottom>
                <div className="education-title-div">
                    <h1 className="title-h1 dark">Skills & Experience</h1>
                    <p className="title-p light">more details about what I've done</p>
                </div>
                </Fade>
            </Col>
            </Row>

        {skillsData.map(el => <Row id={el.id} key={el.title} className="padding-20-top">

          {/* Column with Tile, Title and chips */}
          <Col md="4" sm="12">
            <Fade bottom>
              <Table>
                <Row>
                  <Col>
                    <Tilt options={{max:25}}>
                      <img className="skill-tile"  style={{ border: el.border ? 'solid black 0.05px': ''}}src={`/images/tiles/${el.tile_path}`} alt="" />
                    </Tilt>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="title dark">{el.title}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 className="subtitle light">{el.subtitle}</h5>
                  </Col>
                </Row>
                {el.date && <Row>
                  <Col>
                    <h5 className="subtitle dark">{el.date}</h5>
                  </Col>
                </Row>}
                {el.chips && <Row className="center-text">
                  <Col className="pills-container">
                    {el.chips.map(chip =>
                      <span className="pill">
                        {chip}
                      </span>
                    )}
                  </Col>
                </Row>}
                {el.link &&
                  <Row className="center-text">
                    <Col>
                      <a href={el.link} target="_blank" rel="noreferrer" className="btn btn-outline-secondary rounded-lg">Visit Site</a>
                    </Col>
                  </Row>
                }
              </Table>
            </Fade>
          </Col>
          {/* Column containing description and images */}
          <Col>
            <Fade bottom>
              <Table>
                <Row>
                  <Col>
                    <div className="margin-10-bottom padding-0-top" dangerouslySetInnerHTML={{__html:el.description}}>
                      {/* {el.description} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Gallery options={{ showHideOpacity: true, bgOpacity: 0.5, shareEl: false, getThumbBoundsFn: undefined }}>
                      <div className="image-scroller">
                        {el.photos && el.photos.map((photo, index) =>
                          // index < 2 ? 
                          <Item
                            original={`images/${el.id}/photos/${photo.url}`}
                            thumbnail={`images/${el.id}/photos/${photo.url}`}
                            width="1024"
                            height="768"
                            title={photo.caption ? photo.caption : ''}
                          >
                            {({ ref, open }) => (
                              <img className="gallery-image margin-5-right" ref={ref} onClick={open} src={`images/${el.id}/photos/${photo.url}`} alt=""/>
                            )}
                          </Item>
                        )}
                      </div>
                    </Gallery>
                  </Col>
                </Row>
              </Table>
            </Fade>
          </Col>
        </Row>)
        }

        <Row>
          <Col className="center-text">
            <Fade bottom>
              <a href="/#skills" className="btn btn-outline-secondary rounded-lg">Return to Home</a>
            </Fade>
          </Col>
        </Row>
      </Table>
    </div>
  }
}

export default SkillsList;
