import './Projects.css';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import Tilt from 'react-tilt'
import Fade from 'react-reveal/Fade';


// PhotoSwipe imports
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import projectsData from '../assets/content/projects.json'


class Projects extends Component {  
  componentDidMount() {  }

  render () {
    return <div className="page-padding-first white-background">
      <Table  id="projects">
        <Row>
            <Col>
              <Fade bottom>
                <div className="education-title-div">
                  <h1 className="title-h1 dark">Projects</h1>
                  <p className="title-p light">the exciting bit</p>
                </div>
              </Fade>
            </Col>
          </Row>
        </Table>

        <div className="padding-20-sides padding-10">
          <Table>
            <Row>
              {projectsData.map(el => 
                <Col lg="6" md="12" id={el.id} key={el.title}> 
                  <Table>
                      <Fade bottom>
                        <Row className='padding-10-top'>
                          {/* Picture and details Column */}
                          <Col md="5" sm="12">
                            <Table>
                              <Row>
                                <Col>
                                  <Tilt options={{max:25}}>
                                    <img className="skill-tile" src={`/images/tiles/${el.tile_path}`} alt="" />
                                  </Tilt>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h1 className="title dark">{el.title}</h1>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h5 className="subtitle light">{el.subtitle}</h5>
                                </Col>
                              </Row>

                              {el.icons && <Row className="center-text">
                                <Col className="pills-container">
                                  {/* {el.icons.map(chip =>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                  )} */}
                                </Col>
                              </Row>}
                              {el.link &&
                                <Row className="center-text">
                                  <Col>
                                    <a href={el.link} target="_blank" rel="noreferrer" className="btn btn-outline-secondary rounded-lg">Visit Site</a>
                                  </Col>
                                </Row>
                              }
                            </Table>
                        </Col>
                        {/* Description Column */}
                        <Col md="7" sm="12">
                          <div className="padding-0-top padding-1-sides" dangerouslySetInnerHTML={{__html:el.description}}/>
                        </Col>
                      </Row>
                    </Fade>
                  </Table>
                </Col>)}
              </Row>
          </Table> 
        </div>
    </div>
  }
}
export default Projects;
