import './Homepage.css';
import './../styles/styles.css';
import { Component } from 'react';

import Particles from "react-particles";
import { loadSeaAnemonePreset } from "tsparticles-preset-sea-anemone";

import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Pulse';
import Tilt from 'react-tilt'

import Header from './../Header/Header';

class Homepage extends Component {
    componentDidMount() { }

    async particlesInit(engine) {
        // this adds the preset to tsParticles, you can safely use the
        await loadSeaAnemonePreset(engine);
    }

    render() {      
        return <div className="Section" id="home">
            <Particles className="particles"  id="tsparticles"
            init={this.particlesInit} 
                options={{
                    background: {
                        color: {
                            value: "#0000",
                        },
                    },
                    fpsLimit: 120,
                    particles: {
                        color: {
                            value: "#4C4E52",
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            directions: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: true,
                            speed: 0.5,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1500,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 1,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 3, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            />
            <Header></Header>
            <Tilt options={{max:20}}>
                <div className="Home-Hi-text">
                    <Slide left><h2 className="white">Hi there 👋</h2></Slide>
                    <Slide left><h1 className="white">I'm <span className="light">Rhydian</span></h1></Slide>
                </div>
            </Tilt>
            <Pulse>
                <div className='Home-icon-scroll' />
            </Pulse>
        </div>
  }
}

export default Homepage;
