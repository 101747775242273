import { Component } from 'react';

import Homepage from './Homepage/Homepage';
import About from './About/About';
import Education from './Education/Education';
import Skills from './Skills/Skills';
import Projects from './Projects/Projects';
import Footer from './Footer/Footer';

import './App.css';

import CV from './CV/CV';
import SkillsList from './Skills/SkillsList/SkillsList';
import ProjectsList from './Projects/ProjectsList/ProjectsList';

import Flashcards from './Flashcards/Flashcards';
import FlashcardsPrivacyPolicy from './Flashcards/PrivacyPolicy/PrivacyPolicy';
import FlashcardsTermsAndConditions from './Flashcards/TermsAndConditions/TermsAndConditions';

import { Route, Switch } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component{

  componentDidMount() {  
  }

  render () {
    return (
    <div>
      <Switch>
        {/* SKILLS LIST ROUTE */}
        <Route path="/skills" component={SkillsList}/>

        {/* PROJECTS LIST ROUTE */}
        <Route path="/projects" component={ProjectsList}/>        
        
        {/* CV ROUTE */}
        <Route path="/cv" component={CV}/>
  
        {/* FLASHCARDS ROUTES */}
        <Route path="/flashcards/privacy-policy" component={FlashcardsPrivacyPolicy}/>
        <Route path="/flashcards/terms-and-conditions" component={FlashcardsTermsAndConditions}/>
        <Route path="/flashcards" component={Flashcards}/>


        {/* HOME ROUTE */}
        <Route
          render={ () => (
            <div>
              <Homepage></Homepage>
              <About></About>
              <Education></Education>
              <Projects></Projects>
              <Skills></Skills>
              <Footer></Footer>
            </div>
          )}
        />
      </Switch>
    </div>
    ); 
  }
}

export default App;
