import './About.css';
import meImage from './../assets/images/me-square.png';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFile } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt'

class About extends Component {  
  componentDidMount() {  }

  render () {
    return <div className="page-padding-first white-background">
      <Table id="about">
        <Row>
          <Col>
          <Fade bottom>
            <div className="about-title-div">
              <h1 className="title-h1 dark">About</h1>
              <p className="title-p light">a little bit about me</p>
            </div>
          </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <Row className="padding-20-sides padding-10">
                <Col sm="12" md="4" lg="3" className="me-image-center padding-10-top-bottom">
                  {/* <p>size 4</p> */}
                  <Fade bottom>
                    <Tilt options={{max:25}}>
                        <img className="me-image" src={meImage} alt=""></img>
                    </Tilt>
                  </Fade>
                </Col>
                <Col sm="12" md="8" lg="9" className="padding-10-top-bottom">
                  <Fade bottom>
                    <p>
                      Hi there and welcome to my website. This is a small project I've been working on to 
                      showcase some things about me and my work.
                      {/* <br></br><br></br>
                      I am currently studying towards a bachelor's degree in Computer Science at the
                      University of Liverpool - which I am due to complete in 2022. */}
                      <br></br><br></br>
                      I'm super passionate towards anything tech and love to learn new things.
                      <br></br><br></br>
                      A few things I enjoy in my spare time include watching a game of rugby, and recently
                      I have started learning about the stock market which has really gained my interest.
                      <br></br><br></br>
                      I keep this website as updated as possible, so do come back to see the progress on
                      the projects I'm working on.
                      <br></br><br></br>
                      Think you have something I might be interested in? Don't hesitate to contact me via email
                      or LinkedIn.
                    </p>

                    <a href={'https://github.com/rhydsjones011'} target="_blank" rel="noreferrer" className="btn btn-outline-secondary icon-margin rounded-lg"><FontAwesomeIcon icon={faGithub} />&nbsp; GitHub</a>
                    <a href={'https://www.linkedin.com/in/rhydian-j'} target="_blank" rel="noreferrer" className="btn btn-outline-secondary icon-margin rounded-lg"><FontAwesomeIcon icon={faLinkedin} />&nbsp; LinkedIn</a>
                    <a href={'mailto:rhydsjones011@gmail.com?subject=Hello from your website!'} target="_blank" rel="noreferrer" className="btn btn-outline-secondary icon-margin rounded-lg"><FontAwesomeIcon icon={faEnvelope} />&nbsp; Email</a>
                    <a href="/cv" className="btn btn-outline-secondary icon-margin rounded-lg"><FontAwesomeIcon icon={faFile} />&nbsp; CV</a>
                  </Fade>
                </Col>
              </Row>
            </Table>
          </Col>
        </Row>
      </Table>

    </div>
  }
}

export default About;
