import './Footer.css';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

class Footer extends Component {  
  componentDidMount() {  }

  render () {
    const today = new Date();
    const year = today.getFullYear();
    
    return <div className="dark-background page-padding-sides">
      <Table className="no-margin paddin-5">
        <Row>
          <Col sm="6" md="8" className="padding-5 white">© Rhydian Jones {year}</Col>
          <Col sm="6" md="4" className="padding-5 white">Website Designed & Built by Rhydian Jones</Col>
        </Row>
      </Table>
    </div>
  }
}

export default Footer;
