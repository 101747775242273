import './CV.scss';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import Fade from 'react-reveal/Fade';
import Header from './../Header/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

class CV extends Component {
  componentDidMount() {
  }

  render() {
    return <div className="page-padding padding-40-top">
      <Header blackLink={true}></Header>
      <Table>
        <Fade bottom>
          <Row>
            <Col>
                <div className="education-title-div">
                  <h1 className="title-h1 dark">CV</h1>
                  <p className="title-p light">Curriculum Vitae</p>
                </div>
            </Col>
          </Row>
          <Row>
            <Col class="right-text">
              <a className="btn btn-outline-secondary icon-margin rounded-lg" target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vRt7JpIb1-QhbAxxyCs2iOTBAAgnaYgi3VmSjGrbh4-IbtD6-JELRZfleGsGkm3o90dpeJyhC2EMb3F/pub"><FontAwesomeIcon icon={faFile} />&nbsp; View Document Copy</a>
            </Col>
          </Row>
          <Row>
            <Col className='center-text'>
              {/* <div className='google-doc'> */}
                <iframe title="cv" className="cv-iframe" src="https://docs.google.com/document/d/e/2PACX-1vRt7JpIb1-QhbAxxyCs2iOTBAAgnaYgi3VmSjGrbh4-IbtD6-JELRZfleGsGkm3o90dpeJyhC2EMb3F/pub?embedded=true"></iframe>
              {/* </div> */}
            </Col>
          </Row>
        </Fade>
      </Table>
    </div>
  }
}

export default CV;
