import './Flashcards.scss';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import flashcardsLogo from './../assets/flashcards/flashcards-icon.png';
import flashcardsQR from './../assets/flashcards/flashcards-qr.png';
import flashcardsScreen from './../assets/flashcards/flashcards-screen.png';

class Flashcards extends Component {  
  componentDidMount() {  }

  render () {
    return <div className="page-padding">
      <Table>
        <Row>
          <Col md='6' sm='12'>
            <Table>
              <Row>
                <Col>
                  <img className='flashcards-logo' src={flashcardsLogo} alt=""></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className='flashcards-title'>Flashcards - Learn anywhere</h1>
                  <p className='by-p'>By Rhyd Projects</p>
                  <br></br>
                  <p className='flashcards-description-p'>Create your own custom digital Flashcards on the go on your phone or tablet!</p>
                </Col>
              </Row>
              <Row>
                <Col sm='6' className='center-text g-play-col'>
                  <a className='g-play' href='https://play.google.com/store/apps/details?id=co.uk.rhydprojects.flashcards&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                </Col>
                <Col sm='6' className='center-text g-play-col'>
                  <img className='g-play-qr' src={flashcardsQR} alt=""></img>
                </Col>
              </Row>
              <Row>
                <Col className='padding-5-top center-text'>
                  <a href='/flashcards/terms-and-conditions'  className="btn btn-outline-warning icon-margin rounded-lg">Terms & Conditions</a>
                  <a href='/flashcards/privacy-policy'  className="btn btn-outline-warning icon-margin rounded-lg">Privacy Policy</a>
                </Col>
              </Row>
            </Table>
          </Col>
          <Col md='6' sm='0' className='flashcards-screen-col'>
              <img className='flashcards-screen' src={flashcardsScreen} alt=""></img>
          </Col>
        </Row>
      </Table>
    </div>
  }
}

export default Flashcards;
