import './Education.css';
import './../styles/styles.css';
import lhslogo from './../assets/images/lhs-logo.jpg';
import livlogo from './../assets/images/uol-logo.jpg';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import Fade from 'react-reveal/Fade';

class Education extends Component {  
  componentDidMount() {  }

  render () {
    return <div className="page-padding-first white-background">
      <Table id="education" className="no-margin-bottom">
        <Row>
          <Col>
            <Fade bottom>
              <div className="education-title-div">
                <h1 className="title-h1 dark">Education</h1>
                <p className="title-p light">let's do a bit of learning</p>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row className="padding-20-sides padding-10">
          <Col sm="12" md="6">
            {/* Llanfyllin Table */}
            <Fade bottom>
              <Table className="center-text">
                <Row><Col sm="12" className="padding-5-bottom"><img className="logo-image" src={lhslogo} alt=""></img></Col></Row>
                <Row><Col>
                  <h4 className="dark no-padding-margin">Ysgol Uwchradd Llanfyllin</h4>
                  <p className="no-padding-margin light">2018-2020</p>
                </Col></Row>
                <Row><Col>
                  <p className="no-margin">GCSE's & A Level</p>
                </Col></Row>
                <Row><Col>
                  <p className="no-padding-margin">12 GCSEs A* - C</p>
                  <p className="no-padding-margin">BBC A Level</p>
                </Col></Row>
              </Table>
            </Fade>
          </Col>
          <Col sm="12" md="6">
            {/* UOL table */}
            <Fade bottom>
                <Table className="center-text">
                    <Row><Col sm="12"  className="padding-5-bottom"><img className="logo-image" src={livlogo} alt=""></img></Col></Row>
                    <Row><Col>
                    <h4 className="dark no-padding-margin">University of Liverpool</h4>
                    <p className="no-padding-margin light">2018-Present</p>
                    </Col></Row>
                    <Row><Col>
                    <p className="no-margin">BSC Computer Science With Year In Industry</p>
                    <p>(Industrial Year completed at Gloversure)</p>
                    </Col></Row>
                    <Row><Col>
                    <p className="no-padding-margin">Year 1 81%</p>
                    <p className="no-padding-margin">Year 2 75%</p>
                    <p className="no-padding-margin">Year 3 81%</p>
                    </Col></Row>
                </Table>
            </Fade>
          </Col>
        </Row>
      </Table>

    </div>
  }
}

export default Education;
