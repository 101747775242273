import './Skills.css';
import './../styles/styles.css';
import { Component } from 'react';
import { Table, Col, Row } from 'react-bootstrap';

import angularIonic from './../assets/images/angular-ionic.png';
import gloversure from './../assets/images/gloversure.png';
import lgofStudents from './../assets/images/guild.png';
import laravel from './../assets/images/laravel.png';
import peerMentor from './../assets/images/peer-mentor.png';
import cymraeg from './../assets/images/cymraeg.png';

import Fade from 'react-reveal/Fade';

class Skills extends Component {
  componentDidMount() { }

  render() {
    return <div>
      <div className="page-padding-first dark-background">
        <Table id="skills" style={{ marginBottom: "0" }}>
          <Row>
            <Col>
              <Fade bottom>
                <div className="education-title-div">
                  <h1 className="title-h1 white">Skills & Experience</h1>
                  <p className="title-p light">a few things to talk about</p>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row className="padding-20-sides padding-10">
            <Col>
              <Table>
                <Row>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#gloversure"><img className="skill-image" src={gloversure} alt=""/></a></p></Fade></Col>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#guild"><img className="skill-image" src={lgofStudents} alt=""/></a></p></Fade></Col>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#angular-ionic"><img className="skill-image" src={angularIonic} alt=""/></a></p></Fade></Col>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#laravel"><img className="skill-image" src={laravel} alt=""/></a></p></Fade></Col>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#peer-mentor"><img className="skill-image" src={peerMentor} alt=""/></a></p></Fade></Col>
                  <Col sm="12" md="6" lg="4" style={{padding: "4px"}}><Fade bottom><p className="no-margin"><a href="/skills#cymraeg"><img className="skill-image" src={cymraeg} alt=""/></a></p></Fade></Col>
                </Row>
                <Row className="center-text padding-10-top">
                  <Col>
                    <Fade bottom>
                      <a href={'/skills'} className="btn btn-outline-light rounded-lg">View All Skills & Experiences</a>
                    </Fade>
                  </Col>
                </Row>
              </Table>
            </Col>
          </Row>
        </Table>
      </div>
    </div>

  }
}

export default Skills;
