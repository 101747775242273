import './Header.css';
import './../styles/styles.css';
import { Component } from 'react';
import Fade from 'react-reveal/Fade';
import logo from './../assets/r-logo.png';

class Header extends Component {  
  componentDidMount() {  }

  render () {
    return <div>
        <Fade top>
            <div className='navBar'>
                {/* Logo Div */}
                <div className='logoDiv'>
                    <a href="/">
                        <img className="r-logo-image" src={logo} alt=""></img>
                    </a>
                </div>
                {/* Links Div */}
                <div className='linksDiv'>
                    <a style={{color: this.props.blackLink ? "black" : ''}} className='navLink' href="/#about">About</a>
                    <a style={{color: this.props.blackLink ? "black" : ''}} className='navLink' href="/#projects">Projects</a>
                    <a style={{color: this.props.blackLink ? "black" : ''}} className='navLink' href="/#skills">Skills</a>
                    <a style={{color: this.props.blackLink ? "black" : ''}} className='navLink' href="/cv">CV</a>
                </div>
            </div>
        </Fade>
    </div>
  }
}

export default Header;
